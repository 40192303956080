import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/logo-alt.svg';
import './footer.scss';

export default function Footer() {
  return (
    <footer className="c-footer">
      <div className="l-wrapper">
        <div className="l-row">
          <div className="l-col-12">
            <div className="c-footer__top">
              <div className="l-row">
                <div className="l-col l-col-9">
                  <div className="c-footer__links">
                    <img src={Logo} alt="" />
                    <p>Where Your Wishes Come True</p>
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/privacy">Privacy</Link>
                      </li>
                      <li>
                        <Link to="/terms">Terms</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="l-col l-col-3">
                  {/* <div className="c-footer__links">
                    <p>Download the Gotok App</p>
                    <ul style={{ marginBlockStart: '-5px' }}>
                      <li>
                        {' '}
                        <a
                          className=""
                          target="_blank"
                          href="https://play.google.com/store/apps/details?id=app.gotok"
                          rel="noreferrer"
                        >
                          {' '}
                          <img
                            src={GooglePlay}
                            alt="Playstore download"
                            className=""
                          />{' '}
                        </a>
                      </li>
                      <li>
                        <a
                          className=""
                          target="_blank"
                          href="https://apps.apple.com/ng/app/gotok/id6444884980"
                          rel="noreferrer"
                        >
                          {' '}
                          <img
                            src={AppStore}
                            alt="Appstore download"
                            className=""
                          />{' '}
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="l-row">
          <div className="l-col-12">
            <div className="c-footer__bottom">
              <div className="l-row">
                <div className="l-col l-col-6">
                  <div className="c-footer__links">
                    Gotok, Inc &copy; {new Date().getFullYear()} Reserved.
                  </div>
                </div>
                <div className="l-col l-col-6">
                  {/* <div className="c-footer__socials">
                    <a href="https://twitter.com/gotokhq">
                      <img src={SocialTwitterSVG} alt="twitter" />
                    </a>
                    <a href="https://discord.gg/gotok" alt="discord">
                      <img src={SocialDiscordSVG} alt="twitter" />
                    </a>
                    <a
                      href="https://www.instagram.com/gotokhq/"
                      alt="instagram"
                    >
                      <img src={SocialInstagramSVG} alt="twitter" />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
