import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { motion } from 'framer-motion';
// import { TwitterTweetEmbed } from 'react-twitter-embed';
// import { IntercomProvider, useIntercom } from 'react-use-intercom';
// import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import Header, { LandingHeaderLinks } from '../../components/header/header';
import Footer from '../../components/footer/footer';
import 'react-toastify/dist/ReactToastify.css';
import './landing.scss';

import PhoneImage from './images/phone2.webp';
import TopImage from './images/top.webp';
import LeftImage from './images/bottom.webp';
import BottomImage from './images/left.webp';
import GooglePlay from './images/Google-play.svg';
import AppStore from './images/Appstore.svg';
import PSNCard from './images/psn-card.svg';
import AmazonCard from './images/amazon-card.svg';
import AppleCard from './images/apple-card.svg';

/* Framer Motion Variant Options */
// header animation
const headerMediaAnimation = {
  container: {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.28,
        delay: 0.1,
        delayChildren: 0.2,
        duration: 0.3,
      },
    },
  },

  firstImage: {
    hidden: { opacity: 0, scale: 0.5, y: 350 },
    show: { opacity: 1, scale: 1, y: 0 },
  },

  secondImage: {
    hidden: {
      opacity: 0,
      // x: -100,
      clipPath: 'inset(10% 50% 90% 50% round 10px)',
    },
    show: {
      opacity: 1,
      // x: 0,
      clipPath: 'inset(0% 0% 0% 0% round 10px)',
    },
  },

  thirdImage: {
    hidden: {
      opacity: 0,
      // x: 100,
      clipPath: 'inset(10% 50% 90% 50% round 10px)',
    },
    show: {
      opacity: 1,
      // x: 0,
      clipPath: 'inset(0% 0% 0% 0% round 10px)',
    },
  },

  fourthImage: {
    hidden: {
      opacity: 0,
      // x: -100,
      clipPath: 'inset(10% 50% 90% 50% round 10px)',
    },
    show: {
      opacity: 1,
      // x: 0,
      clipPath: 'inset(0% 0% 0% 0% round 10px)',
    },
  },
};

/* end Framer Motion Options */

function Landing() {
  // const ref = useRef(null);
  // const isInView = useInView(ref, { once: true });

  // const options = {
  //   smooth: true,
  // };

  const el = useRef();
  const q = gsap.utils.selector(el);
  const tl = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap
        .timeline()
        .from(q('.hidetextref'), {
          duration: 1.5,
          y: '150%',
          stagger: 0.6,
          ease: 'power4.out',
          delay: 0.6,
        })
        .from(q('.hidepara'), {
          duration: 0.8,
          y: '150%',
          stagger: 0.6,
          delay: 1.3,
        });
    }, el);

    return () => ctx.revert();
  }, [q]);

  return (
    // <LocomotiveScrollProvider options={options} containerRef={ref}>
    <main>
      <div className="c-home">
        <section className="c-manifest">
          <div className="c-grid" />
          <div className="c-overlay" />
          <Header>
            <LandingHeaderLinks />
          </Header>
          <div className="l-wrapper">
            <div className="l-row">
              <div className="l-col-12">
                <motion.div
                  // initial={{ y: 400 }}
                  // animate={{ y: 0 }}
                  // transition={{ duration: 0.45 }}
                  className="c-manifest__text"
                  ref={el}
                >
                  {/* <Link to="/referral" className="referral">
                    Refer a friend & Get 25%<span>→</span>
                  </Link> */}
                  <h2>
                    <span className="hidetextref">
                      Where Your Wishes Come True!
                    </span>

                    {/* <span>
                      cash on
                      <i className="word-wrap">
                        <span className="animatedword animated-in">
                          {' '}
                          <img src={amazonImage} alt="amazon" /> amazon
                        </span>
                        <span
                          className="animatedword"
                          style={{ color: '#04c167' }}
                        >
                          {' '}
                          <img src={uberImage} alt="uber" /> uberEats
                        </span>
                        <span
                          className="animatedword"
                          style={{ color: '#ff615d' }}
                        >
                          {' '}
                          <img src={tinderImage} alt="tinder" /> tinder
                        </span>
                        <span
                          className="animatedword"
                          style={{ color: '#007ec9' }}
                        >
                          <img src={psnImage} alt="psn network" /> Playstation
                        </span>
                      </i>
                    </span> */}
                  </h2>
                  <p>
                    <span className="hidepara">
                      Let fans fulfill your wish list and spend instantly with a
                      Gotok card.
                    </span>
                  </p>
                  {/* <Email /> */}
                  {/* <img
                    src={ManifestCurrencySVG}
                    alt="currency"
                    className="c-manifest__illustration c-manifest__illustration--currency"
                  /> */}
                  <div className="c-manifest__buttons">
                    <a
                      className=""
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=app.gotok"
                      rel="noreferrer"
                    >
                      {' '}
                      <img
                        src={GooglePlay}
                        alt="Playstore download"
                        className=""
                      />{' '}
                    </a>
                    <a
                      className=""
                      target="_blank"
                      href="https://apps.apple.com/ng/app/gotok/id6444884980"
                      rel="noreferrer"
                    >
                      {' '}
                      <img
                        src={AppStore}
                        alt="Appstore download"
                        className=""
                      />{' '}
                    </a>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
          <section className="c-media">
            <div className="l-wrapper">
              <div className="l-row">
                <motion.div
                  variants={headerMediaAnimation.container}
                  initial="hidden"
                  animate="show"
                  className="c-media__images-wrap"
                >
                  <motion.img
                    variants={headerMediaAnimation.firstImage}
                    src={PhoneImage}
                    alt=""
                    className="c-media__image c-media__image--1"
                  />
                  <motion.img
                    variants={headerMediaAnimation.secondImage}
                    src={TopImage}
                    alt=""
                    className="c-media__image c-media__image--2"
                  />
                  <motion.img
                    variants={headerMediaAnimation.thirdImage}
                    src={LeftImage}
                    alt=""
                    className="c-media__image c-media__image--3"
                  />
                  <motion.img
                    variants={headerMediaAnimation.fourthImage}
                    src={BottomImage}
                    alt=""
                    className="c-media__image c-media__image--4"
                  />
                </motion.div>
                <motion.img
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 1,
                    delay: 0.13,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                  //   delay: 0.1, //   ease: [0, 0.71, 0.2, 1.01],
                  src={AmazonCard}
                  alt=""
                  className="c-media__image c-media__image--5"
                />
                <motion.img
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 1,
                    delay: 0.13,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                  src={PSNCard}
                  alt=""
                  className="c-media__image c-media__image--6"
                />
                <motion.img
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 1,
                    delay: 0.13,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                  src={AppleCard}
                  alt=""
                  className="c-media__image c-media__image--7"
                />
              </div>
            </div>
          </section>
        </section>

        {/* <section className="c-testimonials">
          <div className="l-wrapper">
            <header>
              <h4>Don't just take our word for it</h4>
              <p>Here is what our users think</p>
            </header>
            <div className="c-testimonials__grid">
              <div className="c-testimonials__testimonial wide">
                <a
                  href="https://twitter.com/Nenyenwa0001/status/1608899589796679681?s=20&t=NLzNSPIhMEKOVBkRh4GU6w"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1640415684021698560/WBRPoyWE_x96.jpg"
                      alt="twitter profile"
                    />
                    <span>
                      <h5>Nenyenwa 💎 💎 💎</h5>
                      <p>@Nenyenwa0001</p>
                    </span>
                  </header>
                  <p>
                    Just when our dear country gave us rules on how to manage
                    our funds thereby making transactions so difficult with
                    their new rules on withdrawals, @gotokhq made things much
                    easier. Don’t dull
                  </p>
                </a>
              </div>
              <div className="c-testimonials__testimonial tall">
                <a
                  href="https://twitter.com/EtudorM/status/1609111060321112064?s=20&t=NLzNSPIhMEKOVBkRh4GU6w"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1646188388440064010/8wUv2jFh_x96.png"
                      alt="twiter profile"
                    />
                    <span>
                      <h5>𝚃𝚞𝚍𝚘𝚛🔶𝙺𝚛𝚢𝚙𝚝™️</h5>
                      <p>@EtudorM</p>
                    </span>
                  </header>
                  <p>
                    I have been able to pay for my twitter blue subscription, my
                    discord nitro and bought some courses on @udemy using
                    @gotokhq virtual card and I’m bullish on what they are
                    building! More features to come 2023
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial big">
                <a
                  href="https://twitter.com/CWEmbassy/status/1608799056595419137?s=20&t=NLzNSPIhMEKOVBkRh4GU6w"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1646786376652709888/pQ7Z7grR_x96.jpg"
                      alt="twitter profile"
                    />
                    <span>
                      <h5>Crypto Wealth Embassy🔶⬜️</h5>
                      <p>@CWEmbassy</p>
                    </span>
                  </header>
                  <p>
                    Don’t be scared to try out new things !!! So I wanted to be
                    sure that I could withdraw my funds in my @gotokhq card back
                    into my #Binance, and I must say it was soo fast and easy
                    !!!!!
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial">
                <a
                  href="https://twitter.com/0xtega_/status/1608894336623869952?s=20&t=NLzNSPIhMEKOVBkRh4GU6w"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1609993854400299008/agvNhLHw_x96.jpg"
                      alt="twitter profile"
                    />
                    <span>
                      <h5>0xTega.eth</h5>
                      <p>@0xtega_</p>
                    </span>
                  </header>
                  <p>
                    This is where @gotokhq comes 😎😎 You need no worry about
                    making international payments with any more @gotokhq gat you
                    covered
                    https://twitter.com/Fosudo/status/1608534393215406080
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial">
                <a
                  href="https://twitter.com/ace_taze/status/1610779115468079106?s=46&t=YnDf4n0kdwuboUQPWTs_jg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1623461797087924224/iq_dEPCu_x96.jpg"
                      alt=""
                    />
                    <span>
                      <h5>𝚃𝚊𝚣𝚎🩺📊</h5>
                      <p>@ace_taze</p>
                    </span>
                  </header>
                  <p>
                    @binance and @gotokhq literally blew my mind now. For months
                    I have been looking for a way to be making online payments
                    since most Nigerian cards cannot and it made me unable to
                    renew all my subscriptions. It was very sad
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial">
                <a
                  href="https://twitter.com/TradewithTwiz/status/1615671312399355905"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1618357967715278855/h5m3Fb4B_x96.jpg"
                      alt=""
                    />
                    <span>
                      <h5>Trader Twiz🧠🪔</h5>
                      <p>@TradewithTwiz</p>
                    </span>
                  </header>
                  <p>
                    @gotokhq @phantom I love the solution you guys are building
                    and I’m definitely sharing the goodnews to everyone around
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial">
                <a
                  href="https://twitter.com/pheyiyex/status/1615018187283910661"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1645425133383741440/cYvd_zcB_x96.jpg"
                      alt=""
                    />
                    <span>
                      <h5>Feyi CMADE.lens//(💙,🧡) ⬜.bit</h5>
                      <p>@pheyiyex</p>
                    </span>
                  </header>
                  <p>@gotokhq Keep. Improving love your app.</p>
                </a>
              </div>

              <div className="c-testimonials__testimonial">
                <a
                  href="https://twitter.com/ace_taze/status/1613820821453111297"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1623461797087924224/iq_dEPCu_x96.jpg"
                      alt=""
                    />
                    <span>
                      <h5>𝚃𝚊𝚣𝚎🩺📊</h5>
                      <p>@ace_taze</p>
                    </span>
                  </header>
                  <p>@gotokhq @heliuslabs @hey_wallet This is amazinggggg🔥</p>
                </a>
              </div>

              <div className="c-testimonials__testimonial d-sm-none">
                <a
                  href="https://twitter.com/ruthezimoha/status/1613844271060205568"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1542900175190081536/Akr8rOGA_x96.jpg"
                      alt=""
                    />
                    <span>
                      <h5>Ruth</h5>
                      <p>@ruthezimoha</p>
                    </span>
                  </header>
                  <p>@gotokhq Gotok is definitely providing solutions</p>
                </a>
              </div>

              <div className="c-testimonials__testimonial d-sm-none">
                <a
                  href="https://twitter.com/AisosaUwaifo/status/1611122320931946496"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1633531434466959361/yKYcpAc6_x96.jpg"
                      alt="twitter profile"
                    />
                    <span>
                      <h5>WAisoski❓</h5>
                      <p>@AisosaUwaifo</p>
                    </span>
                  </header>
                  <p>
                    @gotokhq Love your app NGL saw a twitter ads about it
                    yesterday and started using it right away even used my card
                    same yesterday for an online shopping, I love it
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial d-sm-none">
                <a
                  href="https://twitter.com/kenpachi_89/status/1608800574253981696"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1637987821733437441/fpmi4IyF_x96.jpg"
                      alt="twitter profile"
                    />
                    <span>
                      <h5>tony_accardo</h5>
                      <p>@kenpachi_89</p>
                    </span>
                  </header>
                  <p>
                    @CWEmbassy @gotokhq Love the card use for Apple Music and
                    Amazon works well
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial d-sm-none">
                <a
                  href="https://twitter.com/Valenti21188631/status/1603106315093557249"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1611854279932788741/5KprvTa9_x96.jpg"
                      alt="twitter profile"
                    />
                    <span>
                      <h5>Valentine</h5>
                      <p>@Valenti21188631</p>
                    </span>
                  </header>
                  <p>
                    I'm glad using @gotokhq for my online shopping transaction
                    on Aliexpress, fast and reliable to use. All thank to
                    @EtudorM for the awareness and encouragement.
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial d-sm-none">
                <a
                  href="https://twitter.com/Cosmas91/status/1607429135034368001"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1652812955820478465/skoTRLDj_x96.jpg"
                      alt="twitter profile"
                    />
                    <span>
                      <h5>IR🌎</h5>
                      <p>@Investor_ruthh</p>
                    </span>
                  </header>
                  <p>
                    his referal scheme give you the opportunity to earn 25%
                    referal bonus in usdc of every transaction fee
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial d-sm-none">
                <a
                  href="https://twitter.com/tech_emmanuel1/status/1599690180604342272"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1517370506344865792/Q1K5uf7j_x96.jpg"
                      alt="twitter profile"
                    />
                    <span>
                      <h5>Emmanuel 👽 </h5>
                      <p>@tech_emmanuel1</p>
                    </span>
                  </header>
                  <p>
                    @Investor_ruthh @gotokhq This is actually the best of it's
                    kind, stress free 💥👌🏻
                  </p>
                </a>
              </div>

              <div className="c-testimonials__testimonial d-sm-none">
                <a
                  href="https://twitter.com/JacobGabrielI/status/1599892852435144704"
                  target="_blank"
                  rel="noreferrer"
                >
                  <header>
                    <img
                      src="https://pbs.twimg.com/profile_images/1552043623788806144/Ny683jj8_x96.jpg"
                      alt="twitter profile"
                    />
                    <span>
                      <h5>Jacob Gabriel </h5>
                      <p>@JacobGabrielI</p>
                    </span>
                  </header>
                  <p>
                    @Investor_ruthh @gotokhq So many features in this app. I
                    love this. 🔥🔥
                  </p>
                </a>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="c-cta">
          <div className="l-wrapper">
            <div className="l-row">
              <div className="l-col-12">
                <div className="c-cta__text">
                  <img
                    src={CtaCoinSVG}
                    alt=""
                    className="c-cta__illustration c-cta__illustration--coin"
                  />
                  <img
                    src={CtaGlitzSVG}
                    alt=""
                    className="c-cta__illustration c-cta__illustration--glitz"
                  />
                  <img
                    src={CtaCardsPNG}
                    alt=""
                    className="c-cta__illustration c-cta__illustration--cards"
                  />
                  <h3>
                    Download the Gotok app for the easier way to make
                    international payment online
                  </h3>
                  <a
                    href="https://web.gotok.app/"
                    className="c-cta__link"
                    alt="link to download gotok"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <Footer />
      </div>
    </main>
    // </LocomotiveScrollProvider>
  );
}

export default Landing;
