import Header, { LandingHeaderLinks } from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import '../legal.scss';

const Terms = () => (
  <main className="gotok-pay">
    <Header>
      <LandingHeaderLinks />
    </Header>

    <section className="c-legal c-legal--terms">
      <article className="c-legal__hero">
        <div className="l-wrapper">
          <div className="l-row">
            <div className="l-col-9 l-flex-column">
              <h5>Terms & Conditions</h5>
              <h3>The following document outlines Gotok's Terms of Service</h3>
              <h6>Effective Date: December 20, 2024</h6>
            </div>
          </div>
        </div>
      </article>

      <article className="c-legal__content">
        <div className="l-wrapper">
          <div className="l-row">
            <div className="l-col-7">
              <div
                className="c-legal__manifest"
                style={{ margin: '4.8rem 0 2.0rem 0', opacity: '.75' }}
              >
                <p>
                  The following Terms and Conditions (these “Terms”) apply to
                  any person that registers, utilizes, or opens an account
                  through Gotok (mobile applications, website, or APIs). These
                  Terms constitute a legally binding agreement between you and
                  Gotok, Inc., a corporation incorporated under the laws of the
                  State of Delaware, United States.
                </p>
              </div>

              <h4>Interpretation and Definitions</h4>

              <div className="c-legal__text">
                <h2>Interpretation</h2>
                <p>
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions. The following
                  definitions shall have the same meaning regardless of whether
                  they appear in singular or plural.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Definitions</h2>
                <p>For the purposes of these Terms and Conditions:</p>
                <ul>
                  <li>
                    Gotok: Refers to Gotok, Inc., as well as all its
                    subsidiaries and employees responsible for providing the
                    Services.
                  </li>
                  <li>
                    Company (referred to as either “the Company”, “We”, “Us” or
                    “Our” in this Agreement) refers to Gotok, Inc.
                  </li>
                  <li>
                    Platform: Refers to the web and mobile app "Gotok,"
                    including any subdomains thereof, and any platform where the
                    Services are available.
                  </li>
                  <li>
                    User(s): Refers to the natural or legal persons registered
                    on the Platform.
                  </li>
                  <li>
                    Service(s): Refers to every service accessible on the
                    Platform for a User.
                  </li>
                  <li>
                    Terms and Conditions (also referred to as “Terms”) mean
                    these Terms and Conditions that form the entire agreement
                    between You and the Company regarding the use of the
                    Service. This Terms and Conditions agreement has been
                    created with the help of the Terms and Conditions Generator.
                  </li>
                  <li>
                    Third-Party: Refers to any person, natural or legal, apart
                    from Gotok and Users.
                  </li>
                </ul>
              </div>

              <div className="c-legal__text">
                <h2>Acknowledgment</h2>
                <p>
                  These are the Terms and Conditions governing the use of this
                  Service and the agreement that operates between You and the
                  Company. These Terms and Conditions set out the rights and
                  obligations of all users regarding the use of the Service.
                </p>
                <p>
                  Your access to and use of the Service is conditioned on Your
                  acceptance of and compliance with these Terms and Conditions.
                  These Terms and Conditions apply to all visitors, users, and
                  others who access or use the Service.
                </p>
                <p>
                  By accessing or using the Service, You agree to be bound by
                  these Terms and Conditions. If You disagree with any part of
                  these Terms and Conditions, then You may not access the
                  Service.
                </p>
                <p>
                  You represent that you are over the age of 18. The Company
                  does not permit those under 18 to use the Service.
                </p>
                <p>
                  Your access to and use of the Service is also conditioned on
                  Your acceptance of and compliance with the Privacy Policy of
                  the Company. Our Privacy Policy describes Our policies and
                  procedures on the collection, use, and disclosure of Your
                  personal information when You use the Application or the
                  Website and informs You about Your privacy rights and how the
                  law protects You. Please read Our Privacy Policy carefully
                  before using Our Service.
                </p>
                <p>
                  You agree that disputes will be resolved individually and not
                  as part of a class or representative action. By agreeing to
                  these Terms, you waive any rights to participate in a class
                  action against Gotok, Inc.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Use of the Service</h2>
                <p>
                  The Gotok platform allows creators to create and share wish
                  lists with their audience and receive contributions towards
                  fulfilling those wishes. Creators can access their earnings
                  and utilize them through a virtual or physical card provided
                  by the Platform.
                </p>
                <p>
                  Users are responsible for ensuring the information provided on
                  the Platform is accurate and up-to-date. Any fraudulent or
                  misrepresented information may result in account suspension or
                  termination.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Payment and Fees</h2>
                <p>
                  Gotok charges a platform fee of 5-10% on all transactions made
                  through the Platform. This fee will be deducted automatically
                  from the earnings before they are made available for spending
                  or withdrawal.
                </p>
                <p>
                  All fees are non-refundable. Gotok reserves the right to
                  modify the fee structure with prior notice to the Users.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Account Suspension and Termination</h2>
                <p>
                  Gotok reserves the right to suspend or terminate accounts if
                  Users violate these Terms, engage in fraudulent activities, or
                  misuse the Platform in any way. In the event of account
                  suspension or termination, any remaining funds may be held or
                  forfeited based on the nature of the violation.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Limitation of Liability</h2>
                <p>
                  To the maximum extent permitted by law, Gotok shall not be
                  held liable for any direct, indirect, incidental,
                  consequential, or punitive damages arising out of or related
                  to the use of the Platform.
                </p>
                <p>
                  Gotok is not responsible for any loss resulting from the
                  unauthorized use of your account or failure to maintain
                  adequate security measures.
                </p>
              </div>

              <div className="c-legal__text">
                <h2>Contact Us</h2>
                <p>
                  If you have any questions about these Terms and Conditions,
                  You can contact us:
                </p>
                <ul>
                  <li>By email: help@gotok.app</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
    <Footer />
  </main>
);

export default Terms;
